import Script from 'next/script'

const HotJar = () => {
  return (
    <Script
      src="/analytics/hotjar.js"
      strategy="afterInteractive"
      type="text/javascript"
    />
  )
}

export default HotJar
